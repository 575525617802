import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const lifeExpectancy = {
  'Argentina': 76.7,
  'Bolivia': 71.5,
  'Brasil': 75.9,
  'Chile': 80.2,
  'Colombia': 77.3,
  'Costa Rica': 80.3,
  'Cuba': 78.8,
  'Ecuador': 77.0,
  'El Salvador': 73.3,
  'España': 83.4,
  'Estados Unidos': 78.5,
  'Guatemala': 74.3,
  'Honduras': 75.3,
  'Irlanda': 82.3,
  'Italia': 83.5,  // Added Italy
  'Japón': 84.3,
  'México': 75.1,
  'Nicaragua': 74.5,
  'Panamá': 78.5,
  'Paraguay': 74.3,
  'Perú': 76.7,
  'Puerto Rico': 80.1,
  'Reino Unido': 81.3,
  'República Dominicana': 74.1,
  'Rumanía': 75.6,
  'Uruguay': 77.9,
  'Venezuela': 72.1
};

// Instagram story dimensions (9:16 aspect ratio)
const STORY_WIDTH = 1080;
const STORY_HEIGHT = 1920;

function App() {
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [pais, setPais] = useState('');
  const [tiempoRestante, setTiempoRestante] = useState(null);
  const [imagenURL, setImagenURL] = useState({ años: null, meses: null, semanas: null });
  const [tabActiva, setTabActiva] = useState('años');
  const canvasRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  const fetchImageCount = async () => {
    try {
      const { data, error } = await supabase
        .from('image_count')
        .select('count')
        .single();

      if (error) throw error;
      setImageCount(data.count);
    } catch (error) {
      console.error('Error fetching image count:', error);
    }
  };

  const incrementImageCount = async () => {
    try {
      const { data, error } = await supabase.rpc('increment_image_count');

      if (error) throw error;
      setImageCount(data);
    } catch (error) {
      console.error('Error incrementing image count:', error);
    }
  };

  useEffect(() => {
    fetchImageCount();
  }, []);

  const calcularTiempoRestante = async (e) => {
    e.preventDefault();
    if (!fechaNacimiento || !pais) {
      alert('Por favor, completa todos los campos.');
      return;
    }

    const fechaNac = new Date(fechaNacimiento);
    const hoy = new Date();
    const edadActual = hoy.getFullYear() - fechaNac.getFullYear();
    const expectativaVida = lifeExpectancy[pais];

    if (!expectativaVida) {
      alert('No se encontró la expectativa de vida para el país seleccionado.');
      return;
    }

    const añosRestantes = expectativaVida - edadActual;
    const mesesRestantes = Math.floor(añosRestantes * 12);
    const semanasRestantes = Math.floor(añosRestantes * 52);

    setTiempoRestante({
      años: Math.floor(añosRestantes),
      meses: mesesRestantes,
      semanas: semanasRestantes,
      añosTotales: Math.floor(expectativaVida),
      mesesTotales: Math.floor(expectativaVida * 12),
      semanasTotales: Math.floor(expectativaVida * 52),
    });

    // Store user data in the database
    try {
      const { data, error } = await supabase
        .from('user_data')
        .insert([
          { date_of_birth: fechaNacimiento, country: pais }
        ]);

      if (error) throw error;
      console.log('User data stored successfully');
    } catch (error) {
      console.error('Error storing user data:', error);
    }

    setShowResults(true);
  };

  const dibujarVisualizaciones = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = STORY_WIDTH;
    canvas.height = STORY_HEIGHT;

    const dibujarPolaroid = (tipo, pasado, total, dotSize, cols) => {
      // Set the entire canvas background to white
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, STORY_WIDTH, STORY_HEIGHT);

      // Título
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';

      // "UN CAMBIO DE PERSPECTIVA"
      ctx.font = `bold ${STORY_WIDTH * 0.04}px "IBM Plex Mono"`;
      ctx.fillText('"UN CAMBIO DE PERSPECTIVA"', STORY_WIDTH / 2, STORY_HEIGHT * 0.08);

      // "BY RESILIENCIA"
      ctx.font = `${STORY_WIDTH * 0.025}px "IBM Plex Mono"`;
      ctx.fillText('BY RESILIENCIA', STORY_WIDTH / 2, STORY_HEIGHT * 0.11);

      // Dibujar representación
      const margin = STORY_WIDTH * 0.01;
      const startX = margin;
      const startY = STORY_HEIGHT * 0.2;
      const width = STORY_WIDTH - 2 * margin;
      const height = STORY_HEIGHT * 0.65;

      const rows = Math.ceil(total / cols);
      const dotWidth = width / cols;
      const dotHeight = height / rows;
      const size = Math.min(dotWidth, dotHeight, dotSize) * 0.95;
      const gapX = (width - cols * size) / (cols - 1);
      const gapY = (height - rows * size) / (rows - 1);

      for (let i = 0; i < total; i++) {
        const col = i % cols;
        const row = Math.floor(i / cols);
        const x = startX + col * (size + gapX) + size / 2;
        const y = startY + row * (size + gapY) + size / 2;

        ctx.beginPath();
        ctx.arc(x, y, size / 2, 0, Math.PI * 2);
        if (i === pasado) {
          ctx.fillStyle = '#00FF00'; // Bright green for the present
        } else if (i < pasado) {
          ctx.fillStyle = 'black';
        } else {
          ctx.fillStyle = '#4CAF50';
        }
        ctx.fill();
      }

      // Add legend
      const legendY = STORY_HEIGHT * 0.92;
      const legendSize = STORY_WIDTH * 0.02;
      const textOffset = STORY_WIDTH * 0.03;

      // Calculate positions for dots and text with more margin from the right
      const pastX = startX + legendSize;
      const presentX = STORY_WIDTH / 2 - legendSize * 2;
      const futureX = STORY_WIDTH - startX - legendSize * 8; // Increased margin from the right

      // Past dot and text
      ctx.beginPath();
      ctx.arc(pastX, legendY, legendSize, 0, Math.PI * 2);
      ctx.fillStyle = 'black';
      ctx.fill();
      ctx.fillStyle = 'black';
      ctx.textAlign = 'left';
      ctx.font = `${STORY_WIDTH * 0.025}px "IBM Plex Mono"`;
      ctx.fillText('Pasado', pastX + textOffset, legendY + legendSize / 2);

      // Present dot and text
      ctx.beginPath();
      ctx.arc(presentX, legendY, legendSize, 0, Math.PI * 2);
      ctx.fillStyle = '#00FF00'; // Bright green color
      ctx.fill();
      ctx.fillStyle = 'black';
      ctx.textAlign = 'left';
      ctx.fillText('Presente', presentX + textOffset, legendY + legendSize / 2);

      // Future dot and text
      ctx.beginPath();
      ctx.arc(futureX, legendY, legendSize, 0, Math.PI * 2);
      ctx.fillStyle = '#4CAF50';
      ctx.fill();
      ctx.fillStyle = 'black';
      ctx.textAlign = 'left';
      ctx.fillText('Futuro', futureX + textOffset, legendY + legendSize / 2);

      // Move current date to top right corner, higher up
      const fechaActual = new Date().toLocaleDateString();
      ctx.font = `${STORY_WIDTH * 0.025}px "IBM Plex Mono"`; // Slightly smaller font
      ctx.textAlign = 'right';
      ctx.fillStyle = 'black';
      ctx.fillText(fechaActual, STORY_WIDTH - margin - STORY_WIDTH * 0.02, margin + STORY_HEIGHT * 0.03);

      // Add user's input date to top left corner, higher up
      const fechaUsuario = new Date(fechaNacimiento + 'T00:00:00'); // Add time to ensure correct date
      ctx.textAlign = 'left';
      ctx.fillText(fechaUsuario.toLocaleDateString(), margin + STORY_WIDTH * 0.02, margin + STORY_HEIGHT * 0.03);

      // Center the "Representación" text
      const titulo = `Representación en ${tipo}`;
      ctx.font = `${STORY_WIDTH * 0.03}px "IBM Plex Mono"`;
      ctx.textAlign = 'center';
      ctx.fillText(titulo, STORY_WIDTH / 2, STORY_HEIGHT - margin - STORY_HEIGHT * 0.03);

      return canvas.toDataURL('image/png');
    };

    setImagenURL({
      años: dibujarPolaroid('Años', tiempoRestante.añosTotales - tiempoRestante.años, tiempoRestante.añosTotales, STORY_WIDTH * 0.096, 9),
      meses: dibujarPolaroid('Meses', tiempoRestante.mesesTotales - tiempoRestante.meses, tiempoRestante.mesesTotales, STORY_WIDTH * 0.02, 40),
      semanas: dibujarPolaroid('Semanas', tiempoRestante.semanasTotales - tiempoRestante.semanas, tiempoRestante.semanasTotales, STORY_WIDTH * 0.01, 80),
    });

    incrementImageCount();
  }, [tiempoRestante, canvasRef, fechaNacimiento]); // Add fechaNacimiento to the dependency array

  useEffect(() => {
    if (tiempoRestante) {
      dibujarVisualizaciones();
    }
  }, [tiempoRestante, dibujarVisualizaciones]);

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white flex flex-col items-center justify-between p-4 pb-safe">
      {!showResults ? (
        <>
          <div className="w-full text-center py-4">
            <h1 className="text-sm md:text-base">RESILIENCIA</h1>
          </div>

          <div className="flex-grow flex items-center justify-center w-full max-w-md px-4">
            <div className="w-full">
              <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">Aún tienes tiempo</h2>
              <p className="text-center mb-4">
                Un pequeño cambio de perspectiva que ojalá te ayude a valorar cada segundo.
              </p>
              <p className="text-center italic mb-8">
                "La vida es eso que pasa mientras estás ocupado haciendo otros planes" - John Lennon
              </p>
              <form onSubmit={calcularTiempoRestante} className="space-y-4">
                <div>
                  <label htmlFor="fechaNacimiento" className="block mb-1 font-semibold">Fecha de nacimiento:</label>
                  <input
                    type="date"
                    id="fechaNacimiento"
                    value={fechaNacimiento}
                    onChange={(e) => setFechaNacimiento(e.target.value)}
                    className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white"
                  />
                </div>
                <div>
                  <label htmlFor="pais" className="block mb-1 font-semibold">País donde naciste:</label>
                  <select
                    id="pais"
                    value={pais}
                    onChange={(e) => setPais(e.target.value)}
                    className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white"
                  >
                    <option value="">Selecciona un país</option>
                    {Object.keys(lifeExpectancy).sort().map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="text-xs text-center mt-2">Imágenes creadas: {imageCount}</p>
                <button
                  type="submit"
                  className="w-full bg-white text-[#0A0A0A] py-2 px-4 rounded hover:bg-gray-200 transition-colors font-bold"
                >
                  Calcular Tiempo Restante
                </button>
              </form>
              <p className="text-xs italic text-gray-400 text-center mt-2">
                Cálculos basados en la esperanza de vida de cada país.
              </p>
            </div>
          </div>

          <Footer imageCount={imageCount} />
        </>
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              onClick={() => setTabActiva('años')}
              className={`px-4 py-2 rounded ${tabActiva === 'años' ? 'bg-white text-[#0A0A0A]' : 'bg-gray-800'}`}
            >
              Años
            </button>
            <button
              onClick={() => setTabActiva('meses')}
              className={`px-4 py-2 rounded ${tabActiva === 'meses' ? 'bg-white text-[#0A0A0A]' : 'bg-gray-800'}`}
            >
              Meses
            </button>
            <button
              onClick={() => setTabActiva('semanas')}
              className={`px-4 py-2 rounded ${tabActiva === 'semanas' ? 'bg-white text-[#0A0A0A]' : 'bg-gray-800'}`}
            >
              Semanas
            </button>
          </div>
          {imagenURL[tabActiva] && (
            <div className="flex flex-col items-center">
              <div className="w-full max-w-sm overflow-hidden">
                <img
                  src={imagenURL[tabActiva]}
                  alt={`Visualización en ${tabActiva}`}
                  className="mb-4 w-full h-auto max-h-[60vh] object-contain"
                />
              </div>
              <div className="flex space-x-2 mb-2">
                <a
                  href={imagenURL[tabActiva]}
                  download={`tiempo_restante_${tabActiva}.png`}
                  className="bg-white text-[#0A0A0A] py-2 px-4 rounded hover:bg-gray-200 transition-colors"
                >
                  Descargar imagen
                </a>
              </div>
            </div>
          )}
          <button
            onClick={() => setShowResults(false)}
            className="mt-4 mb-8 bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors"
          >
            Volver
          </button>
          <Footer imageCount={imageCount} />
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }} width={STORY_WIDTH} height={STORY_HEIGHT} />
    </div>
  );
}

function Footer({ imageCount }) {
  return (
    <div className="w-full text-center py-4 text-sm">
      <p className="mb-2">Imágenes creadas: {imageCount}</p>
      <p>
        Hecho con amor por{' '}
        <a
          href="https://resiliencia.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-gray-300 transition-colors"
        >
          RESILIENCIA
        </a>
      </p>
    </div>
  );
}

export default App;